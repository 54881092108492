import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { get } from "lodash";
import App from "../components/App";
import HeroInterior from "../components/HeroInterior";
import CardResource from "../components/CardResource";
import Grid from "../components/Grid";
import Container from "../components/Container";
import CategorySubNav from "../components/CategorySubNav";
import CallToActionCard from "../components/CallToActionCard";
import ButtonLink from "../components/Button";
import searchIndex from "../../static/search.json";
import SEO from "../components/SEO";

const Blog = ({ data, location, pageContext }) => {
  const { site } = data;
  const { title } = site;
  const subtitle = get(site, "description.description", "");
  const { categoryID, next, prev, pageNum } = pageContext;
  const { nodes } = categoryID ? data.withCategory : data.withoutCategory;
  let results = [];
  const term = location.search.split("?s=")[1];

  if (location.search) {
    results = searchIndex.index
      .filter(({ title: blogTitle, description }) => {
        const searchTerm = term.toLowerCase().replace("%20", " ");
        const searchTitle = blogTitle.toLowerCase();
        const searchDescription = description
          ? description.description.toLowerCase()
          : "";

        return (
          searchTitle.includes(searchTerm) ||
          searchDescription.includes(searchTerm)
        );
      })
      .map((obj) => ({
        ...obj,
        internal: {
          type: "ContentfulBlog",
        },
      }));
  }

  const BlogFeed = (term ? results : nodes).map(
    ({
      id,
      slug,
      //categories,
      ...rest
    }) => {
      const settings = {
        ...rest,
        url: `/blog/${slug}`,
        key: id,
      };

      if (settings.photoCard) settings.image = settings.photoCard;

      return <CardResource key={id} {...settings} />;
    }
  );

  return (
    <App>
      <SEO {...site} />
      <HeroInterior title={title} subtitle={subtitle} version="purple" />
      <Container large>
        <CategorySubNav />
        <Grid>
          {term && (
            <h2 style={{ width: "100%", marginTop: "2rem" }}>
              {`${results.length} results for "${term}"`}
            </h2>
          )}
          <div style={{ flex: 1 }}>
            <Grid>{BlogFeed}</Grid>
          </div>
          <CallToActionCard />
        </Grid>
      </Container>
      <Container style={{ marginBottom: "2rem" }}>
        <Grid justify="space-between">
          <div>
            {prev && (
              <ButtonLink to={pageNum === 1 ? "/blog" : `/blog/${pageNum}`}>
                {"Previous"}
              </ButtonLink>
            )}
          </div>
          <div>
            {next && !term && (
              <ButtonLink to={`/blog/${pageNum + 2}`}>{"Next"}</ButtonLink>
            )}
          </div>
        </Grid>
      </Container>
    </App>
  );
};

Blog.propTypes = {
  data: PropTypes.shape({
    allContentfulBlog: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    categoryID: PropTypes.string,
    pageNum: PropTypes.number,
    next: PropTypes.bool,
    prev: PropTypes.bool,
  }).isRequired,
};

export default Blog;

export const query = graphql`
  fragment Posts on ContentfulBlog {
    title
    slug
    id: contentful_id
    image: photo {
      alt: title
      fluid(maxWidth: 450) {
        ...GatsbyContentfulFluid
      }
    }
    photoCard: photoCard {
      alt: title
      fluid(maxWidth: 450) {
        ...GatsbyContentfulFluid
      }
    }
    internal {
      type
    }
    author {
      name
      thumbnail {
        alt: title
        fluid(maxWidth: 200) {
          ...GatsbyContentfulFluid
        }
      }
    }
    categories {
      contentful_id
    }
  }

  query blogListQuery($skip: Int!, $limit: Int!, $categoryID: String) {
    site: contentfulResourceArchive(
      contentful_id: { eq: "3pEkNcYbXdNrB2WRplG0aF" }
    ) {
      title
      description {
        description
      }
    }
    withCategory: allContentfulBlog(
      limit: $limit
      skip: $skip
      sort: { fields: date, order: DESC }
      filter: {
        categories: { elemMatch: { contentful_id: { in: [$categoryID] } } }
      }
    ) {
      nodes {
        ...Posts
      }
    }

    ## no filtering
    withoutCategory: allContentfulBlog(
      limit: $limit
      skip: $skip
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        ...Posts
      }
    }
  }
`;
