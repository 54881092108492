import React, { useState } from 'react';
import { Location } from '@reach/router';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import HubspotForm from 'react-hubspot-form';
import { CardLinkWrapper } from './CardResource';
import { Button } from './Button';
import theme from '../theme';

export const Sidebar = styled.div`
  margin-top: .5rem;
  top: 0;
  width: 350px;

  @media (max-width: 1024px){
    width: 100%;
  }
`;

const CardBody = styled.aside`
  ${CardLinkWrapper};
  background-color: ${theme.darkMuted};
  border: 0;
  height: auto;
`;

const CardTitle = styled.h3`
  font-size: 1.689em;
`;

function SearchForm() {
  const [search, setSearch] = useState('');
  return (
    <Location>
      {({ location }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const { pathname } = location;
            navigate(`${pathname}?s=${search}`);
          }}
        >
          <input
            name="search"
            type="search"
            value={search}
            placeholder="Start typing ..."
            onChange={({ target }) => setSearch(target.value)}
            style={{
              marginBottom: '1rem',
            }}
          />
          <Button type="submit" fill small>
            {'Search'}
          </Button>
        </form>
      )}
    </Location>
  );
}

export default function CallToActionCard() {
  return (
    <Sidebar>
      <CardBody>
        <CardTitle>
          {'Search our blog'}
        </CardTitle>
        <SearchForm />
      </CardBody>
      <br />
      <CardBody>
        <CardTitle>
          {'Subscribe to the newsletter'}
        </CardTitle>
        <HubspotForm
          portalId="1771995"
          formId="38059e77-76cb-4555-881e-8c70ad9484aa"
          loading={<div>Loading...</div>}
        />
      </CardBody>
    </Sidebar>
  );
}
