import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { ListNormalized, ListItemInline } from './List';
import theme from '../theme';

const query = graphql`
  query {
    allContentfulCategory(sort: { fields: title, order: ASC }) {
      nodes {
        contentful_id
        title                
        slug
      }
    }
  }
`;

const SubNavContainer = styled.div`
  margin-bottom: 2rem;
  padding: 1vh 3vw 2vh;
`;

const SubNavLink = styled(Link)`
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 2px;
  color: ${theme.tertiary};
  cursor: pointer;
  display: inline-block;  
  line-height: 3;
  margin-right: .5rem;
  padding: 0 3vh;
  transition: border-color 500ms ease-in;

  &:focus, 
  &:hover,
  &.active {
    border-color: ${theme.tertiary};
  }

  @media (max-width: 768px){
    border-color: ${theme.muted};
    margin: .15rem;
  }
`;

export default function CategorySubNav() {
  return (
    <SubNavContainer>
      <StaticQuery
        query={query}
        render={({ allContentfulCategory }) => {
          const { nodes } = allContentfulCategory;

          return (
            <ListNormalized>
              <ListItemInline>
                <SubNavLink
                  to="/blog"
                  activeClassName="active"
                  partiallyActive
                >
                  {'All'}
                </SubNavLink>
              </ListItemInline>

              {/** contentful generated  */
                nodes.map(({ title, slug }) => (
                  <ListItemInline key={slug}>
                    <SubNavLink to={`/category/${slug}`} activeClassName="active">
                      {title}
                    </SubNavLink>
                  </ListItemInline>
                ))}
            </ListNormalized>
          );
        }}
      />
    </SubNavContainer>
  );
}
